import "./Disclaimer.scss";
import DisclaimerItem from "./Item";
import response from "../../utils/markup/disclaimer.json";
import images from "../../assets/images";

export default function Disclaimer() {
    return (
        <div className="container-disclaimer">
            <div className="header">
                <div className="row">
                    <div className="col-10">
                        <h5 className="text">¿Por qué cambiarme a AFP Modelo?</h5>
                    </div>
                    <div className="col-2">
                        <img src={images.face} alt="face" />
                    </div>
                </div>
            </div>
            <div className="body">
                <DisclaimerItem data={response.highlights[0]} image={images.star} />
                <DisclaimerItem data={response.highlights[1]} image={images.heart} />
                <DisclaimerItem data={response.highlights[2]} image={images.aumenta} />
            </div>
        </div>
    )
}