import { useContext, useState, useEffect } from "react";
import { Form, FormControl } from "react-bootstrap";
import { Redirect, useLocation } from "react-router-dom";
import { SendRequest } from "../../services/RequestHandler";
import Context from "../../context/context";
import ConfigManager from "../../settings/ConfigManager";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import images from "../../assets/images";
import Tooltip from "../../components/Tooltip/Tooltip";
import Button from "../../components/Button/Button";
import response from "../../utils/markup/previred.json";
import Swal from "sweetalert2";

export default function FormClaveSeguridad() {
	const { state, authenticated, actions } = useContext(Context);
	const [form, setForm] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [sinCuentas, setSinCuentas] = useState(false);
	const [causalRechazo, setCausalRechazo] = useState([]);
	const [passwordShown, setPasswordShown] = useState(false);

	const params = new URLSearchParams(useLocation().search);
	const value = params.get("token");
	const getStrval = (val) => (val === undefined || val === null ? "" : val);

	const addOption = async (arr) => {
		const steps = [];

		for (var i = 1; i <= arr.length; i++) {
			steps.push(i.toString());
		}

		const swalQueueStep = Swal.mixin({
			confirmButtonText: "Siguiente",
			cancelButtonText: "Anterior",
			progressSteps: steps,
			reverseButtons: true,
		});

		const values = [];
		let currentStep;

		for (currentStep = 0; currentStep < steps.length;) {
			var text = `La ${arr[currentStep].nombreTipo} perteneciente a ${arr[currentStep].origen} presenta el siguiente problema: ${arr[currentStep].causalRechazo}`;

			if (arr[currentStep].causalRechazoCodigo === 15) {
				text = `La ${arr[currentStep].nombreTipo} que intentas traspasar desde AFP ${arr[currentStep].origen} se encuentra bloqueada, dado que presentas una medida cautelar vigente por concepto de la Ley de Pensión de Alimentos (ley 21.484)`;
			}

			if (arr[currentStep].causalRechazoCodigo === 16) {
				text = `La ${arr[currentStep].nombreTipo} que intentas traspasar desde AFP ${arr[currentStep].origen} se encuentra bloqueada, dado que presentas una medida cautelar vigente por concepto de la Ley de Pensión de Alimentos (Retiros 10%)`;
			}

			const result = await swalQueueStep.fire({
				title: "No es posible concretar tu solicitud",
				showCancelButton: currentStep > 0,
				html: text,
				currentProgressStep: currentStep,
			});

			if (result.value) {
				values[currentStep] = result.value;
				currentStep++;
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				currentStep--;
			} else {
				break;
			}
		}
	};

	useEffect(() => {
		if (value) {
			setRedirect(true);
		}
	}, [value]);

	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};

	const handleChange = (event) => {
		event.preventDefault();
		let arrayForm = form;
		arrayForm[[event.target.name]] = event.target.value;
		setForm(arrayForm);
		setDisabled(getStrval(event.target.value) === "");
	};

	const fetchDataClave = async () => {
		try {
			await setLoading(true);

			const data = Decrypt("data");

			const result = await SendRequest({
				url: `${ConfigManager.API_URL}/api/Autenticacion/Clave`,
				method: "POST",
				body: JSON.stringify({
					Rut: data.run,
					Clave: form.claveseg,
					Device: data.device
				})
			});

			if (result.code === 0) {
				const token = result.data.respuestansacu.token;
				data.token = token;
				data.autenticacion = "SACU";

				Encrypt("data", data);

				await fetchDataPrevired(token, data);
			}
		} finally {
			await setLoading(false);
		}
	};

	const fetchDataPrevired = async (token, data) => {
		try {
			await setLoading(true);

			var result = {};
			if (ConfigManager.MARKUP) {
				result = response.datos[ConfigManager.MARKUP_INDEX];
			} else {

				result = await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/Previred`,
					method: "POST",
					body: JSON.stringify({
						token: token,
						codigo: "1034",
						rut: data.run.substring(0, data.run.length - 1),
						rutDv: data.run.substring(data.run.length - 1),
						nombres: data.name,
						apellidoPaterno: data.fatherName,
						apellidoMaterno: data.motherName,
						cuentas: [
							{ codigo: "CCICO" },
							{ codigo: "CCIAV" },
							{ codigo: "CAI" },
							{ codigo: "CAV" },
							{ codigo: "CCICV" },
							{ codigo: "CCIDC" },
							{ codigo: "APVC" },
						],
						otros: "",
						autenticacion: data.autenticacion,
						Device: data.device
					})
				});
			}

			if (result.code === 0) {
				if (result.data.cuentas.length === 0) {
					if (result.data.cuentasRechazo.length !== 0) {
						addOption(result.data.cuentasRechazo);
					}

					Swal.fire({
						icon: "error",
						text: "En este momento, tu AFP presenta indisponibilidad. Te sugerimos que intentes nuevamente más tarde o, si lo prefieres, te pongas en contacto directamente con tu AFP para más información.",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});

				} else {
					var data2 = Decrypt("data");
					data2.name = result.data.afiliado.nombres;
					data2.fatherName = result.data.afiliado.apellidoPaterno;
					data2.motherName = result.data.afiliado.apellidoMaterno;
					//data2.email = result.data.afiliado.email;
					data2.isAuthenticated = true;
					Encrypt("data", data2);

					result.data.token = token;

					result.data.cuentas.forEach((item) => {
						item.fondos.forEach((fondo, i) => {
							fondo.origen = fondo.nombre;
						});
					});

					actions({ type: "setAuthenticated", payload: { ...authenticated, isAuthenticated: true } });

					Encrypt("dataPrevired", result.data);
					setRedirect(true);
				}
			}
		} finally {
			await setLoading(false);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setCausalRechazo([]);

		await fetchDataClave();
	};

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	const noAccounts = () => {
		causalRechazo.forEach(function (cuenta) {
			let texto = `La ${cuenta.nombreTipo} perteneciente a ${cuenta.origen} presenta el siguiente problema: ${cuenta.causalRechazo}`;
			Swal.fire({
				icon: "error",
				text: texto,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		});
	}

	const handleRespuesta = () => {
		Swal.fire({
			icon: "info",
			html: "<div class=\"justify\"><strong class=\"center\">¿Cuál es mi Clave de Seguridad?</strong><p class=\"mt-3\">La Clave de Seguridad es una segunda clave de AFP <strong>diferente</strong> a la que te permite ingresar al sitio web de tu AFP actual.</p><p>Es especial, te permite hacer: cambio o distribución de fondos, giros desde cuentas de ahorro voluntario o <strong>cambio de AFP.</strong></p><p>Si no la tienes, <strong>debes solicitarla en tu actual AFP</strong> o también, puedes cambiarte a AFP Modelo con tu clave única y cédula de identidad vigente.</p></div>",
			confirmButtonText: "Entiendo",
			allowOutsideClick: false
		});
	}

	return (
		<div>
			{redirect && (<Redirect to={{ pathname: "/cuentasyfondo" }} />)}
			{sinCuentas ? noAccounts() : null}
			<Form onSubmit={handleSubmit} onChange={handleChange} className="autentication">
				<div>
					<Button variant="link" type="button" className="link" onClick={handleRespuesta} block >
						¿Cuál es mi clave de seguridad?
					</Button>
				</div>
				<h6 className="sub-title mt-4">
					<div className="row">
						<div className="col-9">
							Clave de seguridad
						</div>
						<div className="col-2">
							<Tooltip text="Es una segunda clave, distinta a la Clave Web, que te permite realizar diversas transacciones con tus ahorros a través del sitio web de forma más segura." />
						</div>
					</div>
				</h6>
				<div className="input-group">
					<FormControl
						name="claveseg"
						type={passwordShown ? "text" : "password"}
						placeholder={passwordShown ? "ABC2020" : "*******"}
						autoComplete="current-password"
					/>
					<span className="input-group-text" onClick={togglePasswordVisiblity} style={{ cursor: "pointer" }}>
						{!passwordShown ? "ver" : "ocultar"} &nbsp;&nbsp;{" "}
						<img src={!passwordShown ? images.eye : images.eyeSlash} width="20" alt="ver" />
					</span>
				</div>
				<div className="mt-3">
					<Button id="B-TW-0005" type="submit" disabled={disabled} loading={state.loading} block>
						Continuar
					</Button>
				</div>
			</Form>
		</div>
	);
}