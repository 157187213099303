import "./ConfirmPage.scss";
import { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { SendRequest } from "../../services/RequestHandler";
import { Decrypt } from "../../utils/SessionCrypto/Crypto";
import Spinner from "react-bootstrap/Spinner";
import AccordionConfirmar from "./AccordionConfirmar";
import StepHeader from "../../components/Header/Ribbon";
import Disclaimer from "./Disclaimer";
import registerStep from "../../services/registerStep";
import Context from "../../context/context";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { mapToPersona, mapToCuentas } from "./utils";
import ConfigManager from "../../settings/ConfigManager";

export default function ConfirmarTraspaso() {
	const { state, actions } = useContext(Context);
	const [dataFile, setDataFile] = useState(null);
	const [redirect, setRedirect] = useState(false);
	const [misDatos, setMisDatos] = useState([]);
	const [paso, setPaso] = useState("");

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);

	useEffect(() => {
		const misdatos = Decrypt("misdatos");
		setMisDatos(misdatos);
	}, []);

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	const confirmar = async (Request) => {
		const result = await SendRequest({
			url: `${ConfigManager.API_BACK_URL}/api/notificar/confirmar`,
			method: "POST",
			body: JSON.stringify(Request)
		});

		return result;
	};

	const guardar = async (Request) => {
		const result = await SendRequest({
			url: `${ConfigManager.API_BACK_URL}/api/notificar/guardar`,
			method: "POST",
			body: JSON.stringify(Request)
		});

		return result;
	};

	const notificar = async (Request) => {
		const result = await SendRequest({
			url: `${ConfigManager.API_BACK_URL}/api/notificar/notificar`,
			method: "POST",
			body: JSON.stringify(Request)
		});

		return result;
	};

	const resultado = async (Request) => {
		const result = await SendRequest({
			url: `${ConfigManager.API_BACK_URL}/api/notificar/resultado`,
			method: "POST",
			body: JSON.stringify(Request)
		});

		return result;
	};

	const ingresar = async (Request) => {
		const result = await SendRequest({
			url: `${ConfigManager.API_BACK_URL}/api/notificar/ingresar`,
			method: "POST",
			body: JSON.stringify(Request)
		});

		return result;
	};

	const onClick = async () => {
		try {
			setShow(true);
			await setLoading(true);
			const dataAuth = Decrypt("data");

			var datos = misDatos.response.Datos;

			if (datos.TipoEmpleado === 6 && datos.Empleadores.length === 0) {
				datos.Empleadores = [{
					id: 1,
					razonSocialempleador: datos.Nombre + " " + datos.ApellidoPaterno + " " + datos.ApellidoMaterno,
					rutempleador: datos.Rut,
					emailNotifiacionempleador: datos.Email,
					telefonoempleador: datos.Telefono,
					direccionempleador: datos.Direccion,
					regionempleador: datos.Region,
					nombreRegion: datos.NombreRegion,
					comunaempleador: datos.NombreComuna,
					nombreComuna: datos.Comuna
				}];
			}

			var Request = {
				Solicitud: misDatos.response.Solicitud,
				Ticket: misDatos.response.Ticket,
				Token: misDatos.response.Token,
				Cuentas: mapToCuentas(misDatos.response.Cuentas.filter(cuenta => cuenta.seleccionado)),
				DatosPersona: mapToPersona(datos, dataAuth.autenticacion, dataAuth.device)
			};

			setPaso("Confirmando");
			var confirmarResult = await confirmar(Request);
			var continuar = confirmarResult.code === 0;

			if (continuar) {
				setPaso("Guardando");
				var guardarResult = await guardar(Request);
				continuar = guardarResult.code === 0;
			}

			if (continuar) {
				setPaso("Notificando");
				var notificarResult = await notificar(Request);
				continuar = notificarResult.code === 0;
			}

			if (continuar) {
				setPaso("Obteniendo resultado");
				var resultadoResult = await resultado(Request);
				continuar = resultadoResult.code === 0;
			}

			if (continuar) {
				setPaso("Ingresando");
				var ingresarResult = await ingresar(Request);

				if (ingresarResult.code === 0) {
					setDataFile(ingresarResult.data);
					setRedirect(true);
				}
			}
		} finally {
			await setLoading(false);
		}
	};

	if (redirect) {
		return <Redirect to={{ pathname: "/bienvenida", state: { data: dataFile } }} />;
	}

	return (
		<>
			<StepHeader paso={3} />
			<div className="col-10 offset-1 col-lg-6 offset-lg-3">
				{misDatos.response && <AccordionConfirmar datos={misDatos.response} />}
			</div>
			<div className="col-10 offset-1 col-lg-6 offset-lg-3">
				<Disclaimer />
			</div>
			<div className="mt-3 mb-3">
				<div className="col text-center">
					<Modal show={show} onHide={handleClose} className="custom-modal-dialog">
						<Modal.Body className="custom-modal-content">
							<div className="text-center mb-3">
								<h4>{paso}</h4>
							</div>
							<div className="text-center">
								<Spinner animation="border" role="status" variant="success">
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							</div>
						</Modal.Body>
					</Modal>

					<Button
						id="B-TW-0009"
						variant="primary"
						onClick={onClick}
						loading={state.loading}
					>
						Confirmar Traspaso
					</Button>
				</div>
				<div className="mt-3">
					<div className="col text-center">
						<Link to={{ pathname: "/misdatos" }}>
							<div className="link">Volver</div>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}
