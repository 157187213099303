import images from "../../assets/images";

// Define un tipo para las posibles claves de las imágenes
type FondoKey = "A" | "B" | "C" | "D" | "E";

// Define the function to capitalize the first letter of a string
export function capitalizeFirstLetter(word: string): string {
    if (typeof word !== "string") return "";

    // Remove trailing period if it exists
    word = word.endsWith(".") ? word.slice(0, -1) : word;

    // Capitalize the first letter and lowercase the rest
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

// Valite if the rut is valid or not
export function isValidIdentifier(rut: string): boolean {
    // Limpia el RUT de puntos y guiones
    const cleanRut = rut.replace(/[.-]/g, '').toUpperCase();

    // Verifica el formato del RUT
    const match = cleanRut.match(/^(\d{1,8})([K\d])$/);
    if (!match) return false;

    const [, rutNumber, checkDigit] = match;
    const rutDigits = rutNumber.split('').reverse();
    let sum = 0;
    let multiplier = 2;

    for (const digit of rutDigits) {
        sum += parseInt(digit, 10) * multiplier;
        multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const calculatedCheckDigit = 11 - (sum % 11);
    const formattedCheckDigit = calculatedCheckDigit === 11 ? '0' : (calculatedCheckDigit === 10 ? 'K' : calculatedCheckDigit.toString());

    return formattedCheckDigit === checkDigit;
};

export function formatIdentifier(rut: string) {
    const newRut = rut.replace(/\./g, '').replace(/\-/g, '').trim().toUpperCase();
    const lastDigit = newRut.substr(-1, 1);
    const rutDigit = newRut.substr(0, newRut.length - 1)

    if (rutDigit.length === 7) {
        let anio = rutDigit.substring(0, 1);
        let mes = rutDigit.substring(1, 4);
        let dia = rutDigit.substring(4, 7);
        return anio + '.' + mes + '.' + dia + '-' + lastDigit
    } else {
        let format = '';
        for (let i = rutDigit.length; i > 0; i--) {
            const e = rutDigit.charAt(i - 1);
            format = e.concat(format);
            if (i % 3 === 0) {
                format = '.'.concat(format);
            }
        }
        return format.concat('-').concat(lastDigit);
    }
}

// get the image of the product
export function getImage(fondo: FondoKey, color: boolean): string {
    // Define un objeto con dos mapas para los fondos con y sin color
    const imageMaps = {
        color: {
            "A": images.A_color,
            "B": images.B_color,
            "C": images.C_color,
            "D": images.D_color,
            "E": images.E_color,
        },
        noColor: {
            "A": images.A,
            "B": images.B,
            "C": images.C,
            "D": images.D,
            "E": images.E
        }
    };

    // Selecciona el mapa adecuado basado en el valor de `color`
    const map = color ? imageMaps.color : imageMaps.noColor;

    // Devuelve la imagen correspondiente o una cadena vacía si no se encuentra
    return map[fondo] || "";
};

// Convert to decimal
export function convertToMoney(value: number) {
    const result = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return result
};

// get tipo cuentas
export function getTipoCuenta(tipo: string) {
    switch (tipo) {
        case "CCICO":
            return "Cuenta Obligatoria";
        case "CCICV":
            return "APV";
        case "CCIDC":
            return "Depósitos Convenidos";
        case "CAV":
            return "Cuenta 2";
        case "CAI":
            return "Cuenta de Ahorro de Indemnización";
        case "CCIAV":
            return "Cuenta de Afiliado Voluntario";
        case "CAPVC":
            return "APV Colectivo";
        case "APVC":
            return "Ahorro Previsional Voluntario Colectivo";
        case "APV":
            return "APV";
        case "CTA2":
            return "Cuenta 2";
        default:
            return `[NO CARGADO]`;
    }
};

export function cleanInput(value: string) {
    // Define la expresión regular para los caracteres permitidos
    const regex = /[^-'A-Za-zÀ-ÖØ-öø-ÿ0-9\s]/g;

    // Reemplaza los caracteres no permitidos por una cadena vacía
    return value.replace(regex, ' ');
};