import "./Ribbon.scss";
import { Container } from "react-bootstrap";
import images from "../../assets/images";
import Timer from "./Timer";
import ConfigManager from "../../settings/ConfigManager";

export default function Ribbon(props) {
	const paso = parseInt(props.paso) - 1;
	const imgs = [images.stepOne, images.stepTwo, images.stepThree];
	const pasos = ["Paso 1", "Paso 2", "Paso 3"];
	const descripciones = ["Selección de cuentas", "Datos Personales", "Confirmación"];

	return (
		<div className="ribbon align-middle text-center mt-3">
			<div className="">
				<div className="offset-lg-4 col-lg-4 ">
					<h5 className="text-gray">Cambiándote a AFP Modelo</h5>
					<p>Sigue el paso a paso para ser parte de Modelo.</p>
				</div>
				<div className="col-lg-12">
					<img src={images.nina} width={100} alt="nina" />
				</div>
			</div>
			<div className="">
				<div className="col-12">
					<div className="steps">
						{ConfigManager.SHOW_TIMER && (<Timer />)}
						<img src={imgs[paso]} alt={`paso${paso + 1}`} />
					</div>
				</div>
			</div>
			<div className="">
				<div className="col-12">
					<div className="banner">
						<Container>
							<strong>{pasos[paso]}</strong> {descripciones[paso]}
						</Container>
					</div>
				</div>
			</div>
		</div>
	);
}