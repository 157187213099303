import "./Footer.scss";
import response from "../../utils/markup/disclaimer.json";
import { Markup } from "interweave";
import ConfigManager from "../../settings/ConfigManager";

export default function Footer() {
	return (
		<footer className="footer-container">
			<div className="footer-inner-container">
				<div className="footer-text-container">
					<div className="footer-text">
						<Markup content={response.footer} />
					</div>
					<div className="footer-copyright">
						<strong>@2024 AFP Modelo. Todos los Derechos Reservados</strong><br />
						<strong>{ConfigManager.VERSION}</strong>
					</div>
				</div>
			</div>
		</footer>
	);
}