import "./Timer.scss";
import { useState, useEffect, useRef } from "react";
import images from "../../assets/images";
import Swal from "sweetalert2";

export default function Timer() {

    const initialMinutes = 0.5; // 30 segundos
    const another = 7; //

    const initialSeconds = initialMinutes * 60;
    const [seconds, setSeconds] = useState(() => {
        const savedSeconds = localStorage.getItem('sessionTime');
        return savedSeconds ? parseInt(savedSeconds, 10) : initialSeconds;
    });

    const intervalRef = useRef(null); // Usar useRef para mantener la referencia del intervalo

    useEffect(() => {
        const updateTimer = () => {
            setSeconds(prevSeconds => {
                if (prevSeconds <= 0) {
                    clearInterval(intervalRef.current); // Detener el intervalo si el tiempo se acaba
                    localStorage.removeItem('sessionTime'); // Limpiar el tiempo guardado

                    // Usar una función asíncrona para manejar Swal.fire
                    const handleTimeout = async () => {
                        try {
                            await Swal.fire({
                                title: "Tiempo agotado",
                                html: `<img src=${images.timer} height="60" /></br></br><p>Tu sesión ha expirado. ¿Quieres continuar con la sesión?</p>`,
                                showCancelButton: true,
                                confirmButtonText: "Sí, continuar",
                                cancelButtonText: "No, volver al inicio",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    // Si el usuario elige continuar, añadir 5 minutos (300 segundos)
                                    setSeconds(prevSeconds => {
                                        const newTime = initialSeconds + another; // Añadir 5 minutos
                                        localStorage.setItem('sessionTime', newTime);
                                        return newTime;
                                    });
                                } else {
                                    // Si el usuario elige no continuar, redirigir al inicio
                                    //window.location.replace("/");
                                    return 0; // Terminar el temporizador si no se está renovando
                                }
                            });
                        } catch (error) {
                            console.error('Error showing Swal.fire:', error);
                        }
                    };

                    handleTimeout();

                }

                // Guardar el tiempo restante en localStorage
                localStorage.setItem('sessionTime', prevSeconds - 1);
                return prevSeconds - 1;
            });
        };

        // Configurar el intervalo para que actualice cada segundo
        intervalRef.current = setInterval(updateTimer, 1000);

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalRef.current);
    }, [initialSeconds]);

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return (
        <div className="container-timer">
            <div>
                {formatTime(seconds)} <img src={images.clock} width="20" alt="Clock" />
            </div>
        </div>
    );
}
